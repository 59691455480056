/**
 * 错误码说明
 * 错误码以4开头，不少于七位，2-4位为前端业务码，5-7位为业务错误码
 * e.g. 40010001
 * 4 前端报错
 * 001 hsuc库业务编码
 * 001 业务错误码
 */
module.exports = {
  'EMAIL_NOT_NULL': {
    code: 40010001,
    message: '邮箱不能为空'
  },
  'PASS_NOT_NULL': {
    code: 40010002,
    message: '密码不能为空'
  },
  'PLEASE_INPUT_AGAIN': {
    code: 40010003,
    message: '请再次输入'
  },
  'IMAGE_CODE_NOT_NULL': {
    code: 40010004,
    message: '图形验证码不能为空'
  },
  'SMS_CODE_NOT_NULL': {
    code: 40010005,
    message: '短信验证码不能为空'
  },
  'MOBIL_NUMBER_NOT_NULL': {
    code: 40010006,
    message: '手机号码不能为空'
  },
  'EMAIL_NOT_MATCH_RULE': {
    code: 40010007,
    message: '邮箱不符合规则'
  },
  'MOBIL_NUMBER_NOT_MATCH_RULE': {
    code: 40010008,
    message: '手机号不符合规则'
  },
  'APP_CODE_NOT_NULL': {
    code: 40010009,
    message: '未检测到应用码'
  },
  'TOKEN_NOT_NULL': {
    code: 40010010,
    message: 'Token不能为空'
  },
  'PASSWORD_NOT_SAME': {
    code: 40010011,
    message: '重复密码不相同'
  },
  'SIGNID_NOT_NULL': {
    code: 40010012,
    message: '公司ID不能为空'
  },
  'CORPID_NOT_NULL': {
    code: 40010013,
    message: 'corpID不能为空'
  },
  'CORPSECRET_NOT_NULL': {
    code: 40010014,
    message: 'corpsecret不能为空'
  },
  'DEP_ID_NOT_NULL': {
    code: 40010015,
    message: '部门id不能为空'
  },
  'DEP_PID_NOT_NULL': {
    code: 40010016,
    message: '部门父级id不能为空'
  },
}
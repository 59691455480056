const version = require("../package.json").version
const Storage = require("./Storage")
const md5 = require("md5")
const {
  Http
} = require("./http")
const {
  isEmail,
  isPhone,
  signData
} = require("./utils")

const ERROR_CODE = require("./errorCode")
const defaultOptions = require("./hs.config")
/**
 * 用户中心类
 */

class HSUC {
  /**
   * HSUC构造函数
   * @param {Object} options 构造参数
   * @param {String} options.appCode 应用码 -- Adsaas 101  Inshoot 102  Tower 103   OA 104   UC 105
   * @param {String} [options.baseURL = /] 自定义基础请求地址
   * @param {String} [options.loginUrl = /login/login/login] 自定义登陆接口
   * @param {String} [options.logoutUrl = /login/login/logout] 自定义退出登陆接口
   * @param {Boolean} [options.requireImgCode = true] 全局图形验证码校验
   * @param {Object} [options.ext = {}] 全局附带数据（请求发送时将被自动发送）
   * @param {Boolean} [options.debug = false] 调试模式开关
   */
  constructor(options = defaultOptions) {
    this.version = version
    for (key in defaultOptions) {
      this[key] = options[key] || defaultOptions[key] || ""
    }
    this._http = new Http(
      options.baseURL || defaultOptions.baseURL,
      options.appCode,
      options.ext,
      options.debug
    )
    this._storage = new Storage()
  }

  /**
   * 邮箱登陆
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {String} options.email 邮箱
   * @param {String} options.password 密码
   * @param {String} [options.imageCode] 图形验证码-非手机号登陆必传
   * @param {Boolean} [options.requireImgCode] 是否需要图形验证码
   * @param {Boolean} [options.ext] 扩展自定义参数,会被自动解构传输
   * @returns {Promise} 登陆结果
   */
  loginByEmail (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post",
        email = "",
        password = "",
        imageCode = "",
        requireImgCode = true,
        ext = {}
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      if (!email) {
        return reject(ERROR_CODE.EMAIL_NOT_NULL)
      }
      if (!isEmail(email)) {
        return reject(ERROR_CODE.EMAIL_NOT_MATCH_RULE)
      }
      if (!password) {
        return reject(ERROR_CODE.PASS_NOT_NULL)
      }
      if (defaultOptions.requireImgCode && requireImgCode && !imageCode) {
        return reject(ERROR_CODE.IMAGE_CODE_NOT_NULL)
      }
      let reqdata = {
        username: email,
        password: md5(password),
        verify: imageCode,
        loginType: "emailPwd",
        ...ext
      }
      this._http
        .request(this.loginUrl, method, reqdata, this.ext)
        .then(res => {
          this._storage.setInfo(res)
          resolve(res)
        })
        .catch(err => reject(err))
    })
  }

  /**
   * 手机号短信验证码登陆
   * @param {Object} options - 手机号码登陆参数
   * @param {String} [options.method='post'] 请求方法
   * @param {String} options.phone 手机号码
   * @param {String} options.code 短信验证码
   * @param {String} [options.imageCode] 图形验证码
   * @param {Boolean} [options.requireImgCode] 是否需要图形验证码
   * @param {Boolean} [options.ext] 扩展自定义参数,会被自动解构传输
   * @returns {Promise} 登陆结果
   */
  loginByPhoneCode (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post",
        phone = "",
        code = "",
        imageCode = "",
        requireImgCode = true,
        ext = {}
      } = options
      require = ["code", "imageCode", "phone"];
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      if (!phone) {
        return reject(ERROR_CODE.MOBIL_NUMBER_NOT_NULL)
      }
      if (!isPhone(phone)) {
        return reject(ERROR_CODE.MOBIL_NUMBER_NOT_MATCH_RULE)
      }
      if (!code) {
        return reject(ERROR_CODE.SMS_CODE_NOT_NULL)
      }
      if (defaultOptions.requireImgCode && requireImgCode && !imageCode) {
        return reject(ERROR_CODE.IMAGE_CODE_NOT_NULL)
      }
      let reqdata = {
        username: phone,
        password: md5(code),
        verify: imageCode,
        loginType: "phoneCaptcha",
        ...ext
      }
      this._http
        .request(this.loginUrl, method, reqdata, this.ext)
        .then(res => {
          this._storage.setInfo(res)
          resolve(res)
        })
        .catch(err => reject(err))
    })
  }

  /**
   * 获取本地登陆信息
   */
  getLocalInfo () {
    return this._storage.getInfo()
  }

  /**
   * 清除本地登陆信息
   */
  clearLocalInfo () {
    this._storage.clearInfo()
  }

  /**
   * 获取签名后的请求数据
   * @param {Object} data 原始请求数据
   * @param [Bollean = false] enableFormData
   * @param [String = get] method 请求方法
   * @returns {Object} 签名后的请求数据
   */
  sign (data = {}, method = "get") {
    return signData.call(this, data, method)
  }

  /**
   * 获取线上用户信息
   * @param {Object} options - 获取用户信息参数
   * @param {String} [options.method='post'] 请求方法
   * @param {String} [options.method='post'] - 请求方法
   */
  getOnlineInfo (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post"
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      if (!this._storage.getInfo().token) {
        return reject(ERROR_CODE.TOKEN_NOT_NULL)
      }
      let reqdata = {}
      this._http
        .request(this.onlineInfo, method, reqdata, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  /**
   * 获取短信验证码
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {String} options.phone 手机号码
   * @param {String} options.imageCode 图形验证码
   */
  getMobileCode (options = {}) {
    let {
      method = "post",
      phone = "",
      imageCode = "",
      requireImgCode = true
    } = options
    return new Promise((resolve, reject) => {
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      if (!phone) {
        return reject(ERROR_CODE.MOBIL_NUMBER_NOT_NULL)
      }
      if (!isPhone(phone)) {
        return reject(ERROR_CODE.MOBIL_NUMBER_NOT_MATCH_RULE)
      }
      if (defaultOptions.requireImgCode && requireImgCode && !imageCode) {
        return reject(ERROR_CODE.IMAGE_CODE_NOT_NULL)
      }

      let reqdata = {
        username: phone,
        verify: imageCode
      }
      this._http
        .request(this.smsUrl, method, reqdata, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  /**
   * 获取图形验证码
   */
  getImageCode () {
    let dat = this.sign()
    return `${this.baseURL}${this.imageCodeUrl}?sg=${dat.sg}&ts=${dat.ts}&tk=${dat.tk}&os=${dat.os}&bid=${dat.bid}`
  }

  /**
   * 重置密码
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {String} options.phone 手机号码
   * @param {String} options.code 手机验证码
   * @param {String} options.password 密码
   * @param {String} options.rpassword 重复密码
   */
  resetPassword (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post",
        phone = "",
        code = "",
        password = "",
        rpassword = ""
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL);
      }
      if (!phone) {
        return reject(ERROR_CODE.MOBIL_NUMBER_NOT_NULL)
      }
      if (!code) {
        return reject(ERROR_CODE.SMS_CODE_NOT_NULL)
      }
      if (!password) {
        return reject(ERROR_CODE.PASS_NOT_NULL)
      }
      if (!rpassword) {
        return reject(ERROR_CODE.PLEASE_INPUT_AGAIN)
      }
      if (rpassword !== password) {
        return reject(ERROR_CODE.PASSWORD_NOT_SAME)
      }

      let reqdata = {
        phone: phone,
        phonecode: md5(code),
        password: md5(password),
        rpassword: md5(rpassword)
      }
      this._http
        .request(this.resetPassUrl, method, reqdata, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  /**
   * 手机注册账号
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {String} options.phone 手机号码
   * @param {String} options.code 手机验证码
   * @param {String} options.password 密码
   * @param {String} options.rpassword 重复密码
   */
  registByPhone (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post",
        phone = "",
        code = "",
        password = "",
        rpassword = ""
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL);
      }
      if (!phone) {
        return reject(ERROR_CODE.MOBIL_NUMBER_NOT_NULL)
      }
      if (!code) {
        return reject(ERROR_CODE.SMS_CODE_NOT_NULL)
      }
      if (!password) {
        return reject(ERROR_CODE.PASS_NOT_NULL)
      }
      if (!rpassword) {
        return reject(ERROR_CODE.PLEASE_INPUT_AGAIN)
      }
      if (rpassword !== password) {
        return reject(ERROR_CODE.PASSWORD_NOT_SAME)
      }

      let reqdata = {
        phone: phone,
        phoneCaptcha: md5(code),
        password: md5(password),
        rpassword: md5(rpassword)
      };
      this._http
        .request(this.registByPhoneUrl, method, reqdata, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  /**
   * 退出登陆
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   */
  logout (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post"
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      if (!this._storage.getInfo().token) {
        return reject(ERROR_CODE.TOKEN_NOT_NULL)
      }
      let reqdata = {}
      this._http
        .request(this.logoutUrl, method, reqdata, this.ext)
        .then(res => {
          this._storage.clearInfo()
          resolve(res);
        })
        .catch(err => reject(err))
    })
  }

  /**
   * 企业微信授权信息和保存
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {Number} options.topsign_id
   * @param {String} options.corpid
   * @param {String} options.corpsecret
   */
  companyAppSign (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post",
        topsign_id = null,
        corpid = "",
        corpsecret = ""
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      if (!topsign_id && topsign_id !== 0) {
        return reject(ERROR_CODE.SIGNID_NOT_NULL)
      }
      if (!corpid && corpid !== 0) {
        return reject(ERROR_CODE.CORPID_NOT_NULL)
      }
      if (!corpsecret) {
        return reject(ERROR_CODE.CORPSECRET_NOT_NULL)
      }
      let reqdata = {
        topsign_id,
        corpid,
        corpsecret
      }
      this._http
        .request(this.companyAppSignUrl, method, reqdata, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  /**
   * 同步部门和用户
   * @param {String} [options.method='post'] 请求方法
   * @returns {Object} 响应状态和部门/用户列表
   */
  depAll (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post", topsign_id = null
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      if (!topsign_id && topsign_id !== 0) {
        return reject(ERROR_CODE.SIGNID_NOT_NULL)
      }
      this._http
        .request(this.depAllUrl, method, {
          topsign_id
        }, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }

  /**
   * 更新部门信息
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {String} options.org_name 部门名称
   * @param {Number} options.id 部门id
   * @param {Number} options.pid 部门的父级id
   * @param {Number} [options.status=1] 1正常 0关闭 默认1
   * @param {Number} [options.order=0] 排序 数字越小越靠前
   * @returns {Object} 响应状态信息
   */
  updateDep (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post",
        org_name,
        id,
        pid,
        status = 1,
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        org_name,
        id,
        pid,
        status,
      }
      this._http
        .request(this.updateDepUrl, method, reqdata, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 添加部门
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {String} options.org_name 部门名称
   * @param {Number} options.topsign_id 公司id
   * @param {Number} options.pid 部门的父级id
   * @param {Number} [options.status=1] 1正常 0关闭 默认1
   * @param {Number} [options.order=0] 排序 数字越小越靠前
   * @returns {Object} 响应状态信息
   */
  addDep (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post",
        org_name,
        topsign_id,
        pid,
        status = 1,
        order = 0
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      if (!org_name) {
        return reject(ERROR_CODE.ORG_NAME_NOT_NULL)
      }
      let reqdata = {
        org_name,
        topsign_id,
        pid,
        status,
        order
      }
      this._http
        .request(this.addDepUrl, method, reqdata, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 获得部门信息
   * @param {Object} options
   * @param {String} [options.method='get'] 请求方法
   * @param {String} options.pid 父级id
   * @param {Number} options.topsign_id 公司id
   * @returns {Object} 响应状态信息
   */
  depinfo (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "get", pid = "0", topsign_id = "0"
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      if (!pid && pid !== 0) {
        return reject(ERROR_CODE.DEP_PID_NOT_NULL)
      }
      if (!topsign_id && topsign_id !== 0) {
        return reject(ERROR_CODE.SIGNID_NOT_NULL)
      }
      let reqdata = {
        pid,
        topsign_id
      }
      this._http
        .request(this.depinfoUrl, method, reqdata, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 获得用户已有权限
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   */
  getUserAuthInfo (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post"
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {}
      this._http
        .request(this.userAuthInfoURL, method, reqdata, this.ext)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 部门人员列表
   * @param {Object} options
   * @param {String} [options.method='get'] 请求方法
   * @param {Number} options.deptId 部门id
   * @param {Number} options.limit 条数
   * @param {Number} options.page 页码
   */
  getDepaMember (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "get", deptId, page = 1, limit = 10
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        deptId,
        page,
        limit
      }
      this._http
        .request(this.depaMemberURL, method, reqdata)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 添加部门人员
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {Object} options.user_mes 用户信息['user_email'=>邮箱, 'user_phone'=>电话, 'user_nickname'=>昵称, 'user_name'=>真实姓名, 'user_sex'=>性别(1|男 2|女)]
   * @param {Array} options.position 用户职位信息
   * @param {Array} options.dep 部门
   */
  addDepaMember (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post", user_mes = {}, position = [], dep = []
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        user_mes,
        position,
        dep
      }
      this._http
        .request(this.addDepaMemberURL, method, reqdata)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 修改部门人员
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {Object} options.user_mes 用户信息['user_email'=>邮箱, 'user_phone'=>电话, 'user_nickname'=>昵称, 'user_name'=>真实姓名, 'user_sex'=>性别(1|男 2|女)]
   * @param {Array} options.position 用户职位信息
   * @param {Array} options.dep 部门
   */
  updateDepaMember (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post", user_mes = {}, position = [], dep = []
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        user_mes,
        position,
        dep
      }
      this._http
        .request(this.updateDepaMemberURL, method, reqdata)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 权限列表
   * @param {Object} options
   * @param {String} [options.method='get'] 请求方法
   * @param {number} options.mid 业务id Adsaas=101,Inshoot=102,Tower=103,OA=104,UC=105
   */
  getPermissionList (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "get", mid
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        mid
      }
      this._http
        .request(this.permissionListURL, method, reqdata)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 部门已有权限列表
   * @param {Object} options
   * @param {String} [options.method='get'] 请求方法
   * @param {number} options.oid 部门id
   */
  getOrgPermissionList (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "get", oid
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        oid
      }
      this._http
        .request(this.orgPermissionURL, method, reqdata)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 人员已有权限列表
   * @param {Object} options
   * @param {String} [options.method='get'] 请求方法
   * @param {number} options.uid 用户id
   * @param {number} options.mid 业务id
   */
  getUserPermissionList (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "get", uid, mid
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        uid,
        mid
      }
      this._http
        .request(this.userPermissionURL, method, reqdata)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 部门分配权限
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {number} options.oid 部门id
   * @param {number} options.mid 业务id
   * @param {Array} options.power 菜单和功能的集合 [1_2,3,4]
   * @param {Object} options.auth_scope 权限范围 1本人 2本门以下 4全公司 5全部 默认 1
   */
  orgAssignPermissions (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post", oid, mid, power, auth_scope
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        oid,
        mid,
        power,
        auth_scope
      }
      this._http
        .request(this.orgAssignPermissionsURL, method, reqdata)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 人员分配权限
   * @param {Object} options
   * @param {String} [options.method='post'] 请求方法
   * @param {number} options.uid 用户id
   * @param {number} options.mid 业务id
   * @param {Array} options.power 没有权限的菜单和功能的集合 [1_2,3,4]
   * @param {Object} options.auth_scope 权限范围 1本人 2本门以下 4全公司 5全部 默认 1
   */
  userAssignPermissions (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "post", uid, mid, power, auth_scope
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        uid,
        mid,
        power,
        auth_scope
      }
      this._http
        .request(this.userAssignPermissionsURL, method, reqdata)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
  /**
   * 职位列表
   * @param {Object} options
   * @param {String} [options.method='get'] 请求方法
   * @param {number} options.topsign 公司id
   */
  getPositionlist (options = {}) {
    return new Promise((resolve, reject) => {
      let {
        method = "get", topsign
      } = options
      if (!this.appCode) {
        return reject(ERROR_CODE.APP_CODE_NOT_NULL)
      }
      let reqdata = {
        topsign
      }
      this._http
        .request(this.positionlistURL, method, reqdata)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
}

module.exports = {
  HSUC: HSUC,
  init: function (options) {
    return new HSUC(options)
  }
};
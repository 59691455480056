module.exports = class Storage {
  constructor() {}

  setInfo(value) {
    localStorage.setItem('HSUC_LOGIN_INFO', JSON.stringify(value))
  }

  getInfo() {
    let local = localStorage.getItem('HSUC_LOGIN_INFO')
    let info = !local || local.toLowerCase() === 'undefined' ? {} : JSON.parse(local)
    return info
  }

  clearInfo() {
    localStorage.removeItem('HSUC_LOGIN_INFO')
  }
}
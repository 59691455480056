const md5 = require('md5')
const isEmail = function (str) {
  return /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(str)
}
const isPhone = function (str) {
  return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(str)
}
const getType = function (data) {
  return Object.prototype.toString.call(data).match(/\s(\w+)/)[1]
}
const filtrate = function (...args) {
  let key;
  [...args].forEach((item) => {
    if (item || item === 0) key = item
  })
  return key === 0 ? 0 : key ? key : ''
}
const encodeUnicode = (str) => {
  var res = [];
  for (var i = 0; i < str.length; i++) {
    res[i] = ('00' + str.charCodeAt(i).toString(16)).slice(-4)
  }
  return '\\u' + res.join('\\u')
}

const numReplacer = function(k,v) {
  if(typeof v==='number'){
    return `${v}`
  }
  return v
}

/**
 * 获取签名后的请求数据
 * @param {Object|FormData} originData 原始请求数据
 * @param [String = get] method 请求方法
 * @returns {Object} 签名后的请求数据
 */
const sign = function (originData = {}, method = 'get') {
  const key = 'E*NE94%r8TBeXCa*'
  let data = {} // 统一数据存储s
  let signData = null
  let allFileds = ['sg'] // 记录所有数据字段便于最终结果重组
  let IS_FORM = false
  // 提取formData中的数据组合到data中准备签名
  if (getType(originData) === 'FormData') {
    IS_FORM = true
    for (let [k, v] of originData.entries()) {
      allFileds.push(k)
      if (!['File', 'Undefined'].includes(getType(v))) {
        data[k] = v
      }
    }
  } else {
    let originFields = Object.keys(originData)
    allFileds = ['sg', ...originFields]
    originFields.forEach(key => {
      data[key] = getType(originData[key]) === 'Undefined' ? '' : originData[key]
    })
  }
  let allData = {
    ts: Date.now(),
    tk: this._storage.getInfo().token || '',
    os: 'API',
    bid: this.appCode,
    ...data
  }

  allFileds = Array.from(new Set(allFileds.concat(Object.keys(allData))))

  // 将数据按照键的字母升序排序
  let dat = {}
  Object.keys(allData)
    .sort()
    .forEach(key => {
      dat[key] = allData[key]
    })

  // 替换所有数字为字符串；将所有中文及中文符号进行 unicode 编码
  let str = ''
  if (method === 'get' || IS_FORM) {
    str = `${JSON.stringify(dat,numReplacer)}${key}`
  } else {
    str = `${JSON.stringify(dat)}${key}`
  }
  dat.sg = md5(str)

  // 重组结果数据
  if (getType(originData) === 'FormData') {
    signData = new FormData()
    allFileds.forEach(key => {
      if (originData.getAll(key).length > 0) {
        originData.getAll(key).forEach(item => {
          signData.append(key, item)
        })
      } else {
        signData.append(key, filtrate(allData[key], originData[key], dat[key]))
      }
    })
  } else {
    signData = {}
    allFileds.forEach(key => {
      signData[key] = filtrate(allData[key], originData[key], dat[key])
    })
  }

  //调试模式
  if (this.debug) {
    console.log(`[HSUC 签名]：${str} ---> ${md5(str)}`)
  }
  return signData
}
module.exports = {
  /**
   * 合并对象属性
   * @param {Object} source
   * @param {Object} target
   */
  mergeAttr(source, target) {
    let result = Object.assign({}, target)
    for (let k in source) {
      result[k] = source[k]
    }
    return result
  },
  isEmail,
  isPhone,
  encodeUnicode,
  signData: sign
}
module.exports = {
  appCode: null, // 业务码
  baseURL: "/", // 默认域名
  loginUrl: "/login/login/login", // 登陆
  logoutUrl: "/login/login/logout", //退出登陆
  onlineInfo: "/login/login/getuserinfo", // 获取线上信息
  imageCodeUrl: "/login/login/getcaptcha", // 获取图形验证码
  smsUrl: "/login/login/phonecaptcha", // 获取短信验证码
  resetPassUrl: "/login/login/resetpassword", // 重置密码
  registByPhoneUrl: "/login/login/register", //手机注册
  companyAppSignUrl: "/Company/company/saveAppid", //授权信息保存或更新
  depAllUrl: "/WeChat/weChat/depAll", //同步部门和用户
  updateDepUrl: "/orgbase/orgbase/modify", //修改部门信息
  addDepUrl: "/orgbase/orgbase/adddep", //添加部门信息
  depinfoUrl: "/orgbase/orgbase/depinfo", //获得部门列表
  userAuthInfoURL: "/auth/userauth/getauthlist", //获得用户权限信息
  depaMemberURL: "/orgbase/orgbase/getuserlist", //部门人员列表
  addDepaMemberURL: "/user/user/ucsaveuser", //添加部门人员
  updateDepaMemberURL: "/user/user/UcUpdateUser", //修改部门人员
  permissionListURL: '/auth/menu/getmenufunctionlist', //权限列表
  orgPermissionURL: '/auth/org/list', //部门已有权限列表
  userPermissionURL: '/auth/user/list', //人员已有权限列表
  orgAssignPermissionsURL: '/auth/org/assignauth', //分配部门权限
  userAssignPermissionsURL: '/auth/user/assignauth', //分配人员权限
  positionlistURL: '/orgbase/orgbase/getpositionlist', //职位列表
  requireImgCode: true,
  ext: {},
  debug: false
}
const axios = require('axios')
const Storage = require('./Storage')
const {
  signData
} = require('./utils')
class Http {
  /**
   * 请求类
   * @param {String} baseURL 基础地址 
   */
  constructor(baseURL, appCode, debug = false) {
    this.appCode = appCode
    this.debug = debug
    this.$http = axios.create({
      baseURL,
      withCredentials: true
    })
    this._storage = new Storage()
  }
  /**
   * 发起请求
   * @param {String} url 
   * @param {String} method 
   * @param [Object = {}] data 
   * @param [Bollen = false] signature 是否启用请求签名
   */
  request(url, method, originData = {}, ext = {}, signature = true) {
    let _method = method.toLowerCase()
    let data = Object.assign(originData, ext)
    if (signature) {
      data = signData.call(this, data, method)
    }
    return new Promise((resolve, reject) => {
      this.$http.request({
        url,
        method: _method,
        params: _method === 'get' ? data : void 0,
        data: _method === 'get' ? void 0 : data
      }).then(res => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          let err = {
            code: res.data.code,
            message: res.data.data.msg
          }
          reject(err)
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
}

module.exports = {
  Http
}